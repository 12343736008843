<script setup>
import {ref, watch, onMounted} from 'vue';
import Button from '@/Components/Button.vue';
import ChosenDoorHandleType from '@/Components/ChosenDoorHandleType.vue';

const model = defineModel({type: Object});
const props = defineProps({
    modelValue: String,
    handleTypes: [Object, Array],
    choosenHandleId: Number,
});
const emit = defineEmits(['update:modelValue', 'scroll', 'chosenHandleChange']);
let currentHandle = ref(props.handleTypes.find(handle => handle.id === props.choosenHandleId) || props.handleTypes[0]);

watch(() => props.choosenHandleId, () => {
    let handle = props.handleTypes.find(handle => handle.id === props.choosenHandleId) || props.handleTypes[0];
    currentHandle.value = handle;
    model.value = handle;
});

const changeHandleType = newHandleType => {
    currentHandle.value = {...newHandleType};
    emit('update:modelValue', newHandleType);
    emit('chosenHandleChange', newHandleType.id);
}

onMounted(() => {
    currentHandle.value = props.handleTypes[0];
});
</script>

<template>
    <div>
        <ChosenDoorHandleType
            :options="currentHandle?.product_images"
            :price="currentHandle?.product_price"
            :article="currentHandle?.vendor_code"
            :brand="currentHandle?.brand || 'MVM'"
            :product_specification="currentHandle?.product_specification"
            :title="currentHandle?.title"
        />
        <div class="flex gap-2 flex-col sm:flex-row sm:items-end">
            <div class="w-full sm:w-1/2 gap-2 grid grid-cols-4">
                <label
                    v-for="handle in props.handleTypes"
                    class="block cursor-pointer col-span-1 bg-white text-center"
                >
                    <input
                        v-model="model"
                        type="radio"
                        :value="handle"
                        class="hidden peer"
                        required
                    />
                    <img
                        :src="handle.src.thumb"
                        :alt="handle"
                        class="mx-auto"
                        @click="changeHandleType(handle)"
                    >
                    <hr
                        class="mt-1 border border-gray-light border-1"
                        :class="{ '!border-black': model === handle }"
                    >
                </label>
            </div>
            <div class="w-full mt-5 sm:w-1/2 sm:mt-0">
                <Button
                    kind="sharp"
                    class="w-full"
                    :aria-label="$t('choseOtherHandle')"
                    @click="emit('scroll')"
                >
                    {{ $t('choseOtherHandle') }}
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="13"
                        height="12"
                        viewBox="0 0 13 12"
                        fill="none"
                    >
                        <path
                            d="M11.5 4.75C10.9667 5.32143 7.72222 8.32143 6.16667 9.75L1.5 4.75"
                            stroke="#5A7B7E"
                            stroke-width="2"
                        />
                        <line
                            y1="-1"
                            x2="9"
                            y2="-1"
                            transform="matrix(0 -1 -1 0 5.41406 9.25)"
                            stroke="#5A7B7E"
                            stroke-width="2"
                        />
                    </svg>
                </Button>
            </div>
        </div>
    </div>
</template>
