<script setup>
import Button from '@/Components/Button.vue';
import {onMounted, ref, watch} from "vue";
import {Modal} from "flowbite";

const props = defineProps({
    classes: String,
    isOpen: Boolean,
    hasCross: Boolean,
})
const modal = ref(null),
    modalInstance = ref(null);

const emit = defineEmits('close')

watch(() => props.isOpen, (open) => {
    const { classList } = document.body;

    if (open) {
        classList.add('overflow-hidden');
        modalInstance.value.show();
    } else {
        classList.remove('overflow-hidden');
        modalInstance.value.hide();
    }
})

onMounted(() => {
    modalInstance.value = new Modal(modal.value, {
        backdrop: 'dynamic',
        onHide: () => emit('close')
    });
})
</script>

<template>
    <div
        ref="modal"
        tabindex="-1"
        aria-hidden="true"
        class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center md:inset-0 h-[calc(100%-1rem)] max-h-full"
    >
        <div class="relative m-4 max-w-2xl max-h-full">
            <div
                class="relative bg-white"
                :class="props.classes"
            >
                <div
                    v-if="props.hasCross"
                    class="text-right mb-4"
                >
                    <Button
                        class="text-black hover:text-green"
                        aria-label="Close"
                        @click="emit('close')"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                        >
                            <path
                                d="M17.8945 4.10547L4.10595 17.8941"
                                stroke="currentColor"
                                class="hover:stroke-green"
                                stroke-width="2.5"
                                stroke-linecap="square"
                            />
                            <path
                                d="M4.10547 4.10547L17.8941 17.8941"
                                stroke="currentColor"
                                class="hover:stroke-green"
                                stroke-width="2.5"
                                stroke-linecap="square"
                            />
                        </svg>
                        <span class="sr-only">Close modal</span>
                    </Button>
                </div>
                <slot></slot>
            </div>
        </div>
    </div>
</template>
