<script setup>
import {computed} from 'vue';
import ResponsivePicture from "@/Components/ResponsivePicture.vue";
import Button from '@/Components/Button.vue';

const props = defineProps({
    item: Object,
    onCartPreview: {
        type: Boolean,
        default: false
    },
    onCheckout: {
        type: Boolean,
        default: false
    }
})

const complexion = computed(() => {
    const complexion = [];
    if (props.item.edge) {
        complexion.push(props.item.doorEdge.title);
    }
    if (props.item.curtains) {
        complexion.push(props.item.doorCurtains.title);
    }
    if (props.item.magneticStopper) {
        complexion.push(props.item.doorMagneticStopper.title);
    }
    if (props.item.magneticMechanism) {
        complexion.push(props.item.doorMagneticMechanism.title);
    }
    return complexion;
})

const handleCover = computed(() => {
    if (!props.item.handleCoverWC.value && !props.item.cylinderCover.value) {
        return [];
    }

    const findCheck = props.item.handleCoverWC.value ? "door_cover_wc" : "cylinder_cover";
    let el = props.item.handleType.value?.related_products?.find(ell => ell.product_type === findCheck);
    if (!el && props.item.handleCoverWC.value) {
        el = props.item.handleType.value?.related_products?.find(ell => ell.title.includes("WC"));
    } else if (!el && props.item.cylinderCover.value) {
        el = props.item.handleType.value?.related_products?.find(ell => !ell.title.includes("WC"));
    }
    return el ? el : [];
})

const emits = defineEmits(['updateCount', 'delete']);

const increment = () => emits('updateCount', props.item.count + 1);
const decrement = () => {
    if (props.item.count >= 1) {
        emits('updateCount', props.item.count - 1)
    }
}

const price = computed(() => new Intl.NumberFormat('uk-UA', {
    currencyDisplay: "narrowSymbol", style: 'currency', currency: 'UAH'
}).format(props.item.price))
</script>

<template>
    <div class="text-black mb-3">
        <div class="flex gap-4">
            <div class="max-w-40">
                <ResponsivePicture
                    :data="{...item?.img, alt: item.title}"
                    img-classes="w-full lg:w-auto"
                />
            </div>
            <div class="font-normal space-y-2">
                <h3 class="text-desc-13">{{ item.title }}</h3>
                <div class="text-xxs leading-tight space-y-2">
                    <p>{{ $t('dimensionHW') + ': ' + item.height.value + '*' + item.width.value }}</p>
                    <p v-if="item.frame">{{ $t('profileColor') + ': ' + item.doorFrame.value }}</p>
                    <p>{{ $t('opening') + ': ' + item.doorOpenOptions.value }}</p>
                    <p v-if="complexion.length">
                        {{ $t('complexion') + ': ' + complexion?.join(', ').toLowerCase() }}
                    </p>
                </div>
            </div>
        </div>

        <div
            v-if="item.handle.value && item.handleType"
            class="flex my-2 gap-4"
        >
            <div class="flex max-w-40 gap-2">
                <template v-for="handleItem in item.handleType.value.product_images.slice(0, 2)">
                    <div class="bg-white">
                        <img
                            class="w-full lg:w-auto"
                            :src="handleItem.medium"
                            :alt="item.handle.title"
                        >
                    </div>
                </template>
            </div>
            <div class="font-normal space-y-2">
                <h3 class="text-desc-13">{{ item.handleType?.value?.title }}</h3>
                <div class="text-xxs leading-tight space-y-2">
                    <p>{{ $t('art') + ' ' + item.handleType.value?.vendor_code }}</p>
                    <p>{{ $t('brand') }}: MVM</p><!-- TODO: Find where brand is in the data -->
                    <p>{{ $t('covering') + ': ' + item.handleType.value?.product_specification?.coating?.value }}</p>
                </div>
            </div>
        </div>

        <div
            v-if="item.handle.value && (item.handleCoverWC.value || item.cylinderCover.value)"
            class="flex gap-4"
        >
            <div class="flex max-w-40 gap-2">
                <template v-for="handleCoverItem in handleCover?.product_images.slice(0, 2)">
                    <div class="bg-white">
                        <img
                            class="w-full lg:w-auto"
                            :src="handleCoverItem.medium"
                            :alt="handleCover.title"
                        >
                    </div>
                </template>
            </div>
            <div class="font-normal space-y-2">
                <h3 class="text-desc-13">{{ handleCover.title }}</h3>
                <div class="text-xxs leading-tight space-y-2">
                    <p>{{ $t('art') + ' ' + handleCover.vendor_code }}</p>
                    <p>{{ $t('brand') }}: MVM</p><!-- TODO: Find where brand is in the data -->
                    <p>{{ $t('covering') + ': ' + handleCover?.product_specification?.coating?.value }}</p>
                </div>
            </div>
        </div>

        <div
            v-if="!onCheckout && !onCartPreview"
            class="flex items-center border border-gray-darkest mt-8 w-fit text-xxs text-black"
        >
            <button
                :disabled="1 === item.count"
                class="w-5 h-5 flex items-center justify-center border-r border-gray-darkest"
                :class="{'cursor-not-allowed opacity-50': 1 === item.count}"
                aria-label="decrement"
                type="button"
                @click="decrement"
            >
                -
            </button>
            <div class="w-5 h-5 flex items-center justify-center">
                {{ item.count }}
            </div>
            <button
                class="w-5 h-5 flex items-center justify-center border-l border-gray-darkest"
                aria-label="increment"
                type="button"
                @click="increment"
            >
                +
            </button>
        </div>
        <div
            v-else-if="!onCartPreview"
            class="mt-8 text-desc-13 text-black font-medium"
        >{{ item.count + ` ${$t('pcs')}.` }}
        </div>

        <div
            v-if="!onCartPreview"
            class="flex justify-between text-desc-13 font-medium "
            :class="onCheckout ? 'mt-4 pb-8' : 'mt-8 pb-12'"
        >
            <p>{{ price + ` ${$t('perSet')}` }}</p>
            <div v-if="!onCheckout">
                <Button
                    class="text-gray-dark underline"
                    :aria-label="$t('delete')"
                    @click="emits('delete', item)"
                >{{ $t('delete') }}</Button>
            </div>
        </div>

        <div
            v-if="onCartPreview"
            class="my-3 text-desc-13"
        >
            <Button
                kind="sharp-small"
                secondary
                :aria-label="$t('earlySetPrice')"
                class="flex justify-between text-black font-extrabold w-full border-gray-darkest"
            >
                <span class="font-extrabold">{{ $t('earlySetPrice') }}</span><span class="font-bold">{{
                    item.price
                }}</span>
            </Button>
        </div>
    </div>
</template>
