<script setup>
import {ref} from "vue";
import {useI18n} from "vue-i18n";
import { Link, router } from "@inertiajs/vue3";
import emitter from "@/plugins/eventBus.js";

const props = defineProps({
    linkSrc: Object,
    items: Object
})

const getImgSource = (src) => {
    let webpImg = src?.webp ? src?.webp?.md : src?.webp?.lg;
    let jpgImg = src?.jpg ? src?.jpg?.md : src?.jpg?.lg;
    return webpImg ? webpImg : jpgImg ? jpgImg : src?.url;
}

const locale = ref(useI18n().locale);
const onNavigateTo = (link, componentId) => {
    if (window.location.href.includes(link)) {
        emitter.$emit('closeMegaMenu');
        emitter.$emit(componentId);
        return;
    }

    router.visit(link, {
        onFinish: () => emitter.$emit(componentId)
    })
}
</script>

<template>
    <div class="flex gap-3 xl:gap-6">
        <Link
            :href="props.linkSrc?.[locale]"
            :aria-label="props.linkSrc?.title?.[locale]"
            class="w-1/2 h-auto hover:opacity-75"
        >
            <img
                :src="getImgSource(props.items?.image)"
                class="h-[42vh] object-cover object-right"
                :alt="props.linkSrc?.title?.[locale]"
            >
        </Link>
        <div class="w-1/2 text-xs text-black-dark  font-normal space-y-4">
            <Link
                :href="props.linkSrc?.[locale]"
                :aria-label="props?.linkSrc?.title?.[locale]"
                class="text-xxs font-semibold uppercase hover:text-green block w-fit mb-8"
            >
                <h4>{{ props?.linkSrc?.title?.[locale] }}</h4>
            </Link>

            <template v-for="item in props.items?.blocks?.[locale]">
                <a
                    :href="props.linkSrc?.[locale] + `/#${item?.id}`"
                    :aria-label="item?.title"
                    class="text-black-dark hover:text-green  font-normal block w-fit"
                    @click.prevent="onNavigateTo(props.linkSrc?.[locale], item?.id)"
                >
                    {{ item?.title }}
                </a>
            </template>
        </div>
    </div>
</template>