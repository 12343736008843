<script setup>
import {Link} from "@inertiajs/vue3";
import ResponsivePicture from "@/Components/ResponsivePicture.vue";

const value = defineModel({type: String});

defineProps({
    imgSrc: String,
    title: String,
    id: String,
    href: String,
    coverImage: Object
})
</script>

<template>
    <div class="mx-auto">
        <Link
            :href="href"
            :aria-label="title"
            class="block w-fit"
        >
            <div class="w-[300px] hover:cursor-pointer">
                <div>
                    <slot name="image">
                        <ResponsivePicture
                            :data="coverImage"
                        />
                    </slot>
                </div>
                <hr
                    class="mt-2 border-gray border-[1.5px]"
                    :class="{ '!border-black': value === id }"
                >
            </div>
        </Link>
    </div>
</template>