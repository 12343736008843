<script setup>
import { ref, onBeforeUnmount } from 'vue';
import { useStore } from "vuex";
import classNames from "classnames";
import Button from '@/Components/Button.vue';
import NewModal from "@/Components/NewModal.vue";
import ContactForm from '@/Components/ContactForm.vue';

const props = defineProps({
    closeBurger: {
        type: Function,
        default: () => {},
    },
    kind: {
        type: String,
        default: 'round-big'
    }
});

const {state} = useStore();
const coordinatesUrl = ref(
    state?.settings?.coordinates ?
        `https://www.google.com/maps/search/?api=1&query=${state?.settings?.coordinates?.latitude},${state?.settings?.coordinates?.longitude}` :
        '#'
);
const btnClasses = {
    'round-big': 'bg-green text-white rounded-full hover:bg-black md:h-24 md:w-24 h-14 w-14 flex justify-center items-center transition-colors duration-300',
    'round-small': 'bg-green text-white rounded-full hover:bg-black h-12 w-12 md:h-14 md:w-14 flex justify-center items-center transition-colors duration-300',
}

const isMobile = !!navigator.userAgent.match (/(Android|iPod|iPhone|iPad)/);
const isOpenContactModal = ref(false);

onBeforeUnmount(() => {
    isOpenContactModal.value = false;
})
</script>

<template>
    <div class="flex justify-center gap-5 md:gap-8">
        <a
            :class="classNames(btnClasses[props.kind])"
            :href="coordinatesUrl"
            :target="coordinatesUrl === '#' ? '_self' : '_blank'"
            aria-label="Go to Coordinates"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="26"
                viewBox="0 0 40 50"
                fill="none"
                class="md:w-10 md:h-12"
                :class="{'md:w-[22px] md:h-[26px]': props.kind === 'round-small'}"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M19.8699 0.000244141H21.026C31.4306 0.0724985 39.6676 8.59851 39.7399 19.0031C39.7399 33.5263 19.8699 50.0002 19.8699 50.0002C19.8699 50.0002 0 33.5263 0 19.0031C0 8.59851 8.30925 0.0724985 18.7139 0.000244141H19.8699ZM19.9418 32.8757C27.673 32.8035 33.9591 26.4451 33.8869 18.7139C33.8146 10.9827 27.4562 4.7688 19.725 4.7688C11.9215 4.84105 5.70767 11.1994 5.77992 18.9306C5.85218 26.7341 12.1383 32.948 19.9418 32.8757Z"
                    fill="white"
                />
            </svg>
        </a>
        <a
            v-if="isMobile"
            :class="classNames(btnClasses[props.kind])"
            :href="`mailto:${state?.settings?.main_email}`"
            aria-label="Write mail"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="17"
                viewBox="0 0 50 33"
                fill="none"
                class="md:w-12 md:h-8"
                :class="{'md:w-[26px] md:h-[17px]': props.kind === 'round-small'}"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M47.3638 32.1618C48.8576 32.1618 50 30.9316 50 29.5256V2.63633C50 1.14248 48.7698 0.00012207 47.3638 0.00012207H2.6362C1.14235 0.00012207 0 1.23035 0 2.63633V29.5256C0 31.0195 1.23023 32.1618 2.6362 32.1618H47.3638ZM22.4078 22.1443C23.1108 22.7594 24.0774 23.1109 24.9561 23.1109C25.9227 23.1109 26.8015 22.7594 27.5045 22.1443L46.9245 5.27263C47.5396 4.74539 47.6275 3.77878 47.1002 3.16367C46.4851 2.54855 45.6064 2.46068 44.9913 2.98792L25.5712 19.8596C25.2198 20.1232 24.6925 20.1232 24.341 19.8596L4.92098 2.98792C4.30587 2.46068 3.33926 2.54855 2.81202 3.16367C2.28478 3.77878 2.37265 4.74539 2.98776 5.27263L22.4078 22.1443Z"
                    fill="white"
                />
            </svg>
        </a>
        <Button
            v-else
            aria-label="Open contact modal popup"
            :class="classNames(btnClasses[props.kind])"
            @click="isOpenContactModal = true; closeBurger()"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="17"
                viewBox="0 0 50 33"
                fill="none"
                class="md:w-12 md:h-8"
                :class="{'md:w-[26px] md:h-[17px]': props.kind === 'round-small'}"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M47.3638 32.1618C48.8576 32.1618 50 30.9316 50 29.5256V2.63633C50 1.14248 48.7698 0.00012207 47.3638 0.00012207H2.6362C1.14235 0.00012207 0 1.23035 0 2.63633V29.5256C0 31.0195 1.23023 32.1618 2.6362 32.1618H47.3638ZM22.4078 22.1443C23.1108 22.7594 24.0774 23.1109 24.9561 23.1109C25.9227 23.1109 26.8015 22.7594 27.5045 22.1443L46.9245 5.27263C47.5396 4.74539 47.6275 3.77878 47.1002 3.16367C46.4851 2.54855 45.6064 2.46068 44.9913 2.98792L25.5712 19.8596C25.2198 20.1232 24.6925 20.1232 24.341 19.8596L4.92098 2.98792C4.30587 2.46068 3.33926 2.54855 2.81202 3.16367C2.28478 3.77878 2.37265 4.74539 2.98776 5.27263L22.4078 22.1443Z"
                    fill="white"
                />
            </svg>
        </Button>
        <a
            v-if="isMobile"
            :class="classNames(btnClasses[props.kind])"
            :href="`tel:${state?.settings?.main_phone_number}`"
            aria-label="Call phone"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                class="md:w-11 md:h-11"
                :class="{'md:w-[24px] md:h-[24px]': props.kind === 'round-small'}"
            >
                <path
                    d="M0.513707 5.67196C0.309236 8.49366 2.39484 13.4827 6.60693 17.5721C10.819 21.6616 15.849 23.5836 18.6707 23.2973C20.7563 23.3382 22.7192 22.0296 23.3735 19.944L23.6598 19.0034C23.8642 18.3082 23.4962 17.613 22.801 17.3677L18.9978 16.1817L16.1353 15.2821C15.4401 15.0776 14.7449 15.4457 14.4995 16.1409L14.2132 17.0814C14.0905 17.4086 14.0497 17.7766 14.0088 18.1038C12.373 17.6948 10.5737 16.6725 8.97878 15.1185C7.38391 13.5645 6.32067 11.8061 5.87083 10.1703C6.19799 10.1294 6.56603 10.0476 6.89319 9.92495L7.83375 9.5978C8.52895 9.35243 8.8561 8.61634 8.65163 7.96203L7.67017 5.09945L6.32067 1.37808C6.0753 0.682882 5.33921 0.35573 4.6849 0.5602L3.74434 0.887353C1.65874 1.58255 0.391025 3.58636 0.513707 5.67196Z"
                    fill="white"
                />
            </svg>
        </a>
        <Button
            v-else
            aria-label="Open contact modal popup"
            :class="classNames(btnClasses[props.kind])"
            @click="isOpenContactModal = true; closeBurger()"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                class="md:w-11 md:h-11"
                :class="{'md:w-[24px] md:h-[24px]': props.kind === 'round-small'}"
            >
                <path
                    d="M0.513707 5.67196C0.309236 8.49366 2.39484 13.4827 6.60693 17.5721C10.819 21.6616 15.849 23.5836 18.6707 23.2973C20.7563 23.3382 22.7192 22.0296 23.3735 19.944L23.6598 19.0034C23.8642 18.3082 23.4962 17.613 22.801 17.3677L18.9978 16.1817L16.1353 15.2821C15.4401 15.0776 14.7449 15.4457 14.4995 16.1409L14.2132 17.0814C14.0905 17.4086 14.0497 17.7766 14.0088 18.1038C12.373 17.6948 10.5737 16.6725 8.97878 15.1185C7.38391 13.5645 6.32067 11.8061 5.87083 10.1703C6.19799 10.1294 6.56603 10.0476 6.89319 9.92495L7.83375 9.5978C8.52895 9.35243 8.8561 8.61634 8.65163 7.96203L7.67017 5.09945L6.32067 1.37808C6.0753 0.682882 5.33921 0.35573 4.6849 0.5602L3.74434 0.887353C1.65874 1.58255 0.391025 3.58636 0.513707 5.67196Z"
                    fill="white"
                />
            </svg>
        </Button>

        <Teleport to="body">
            <NewModal
                classes="p-5 sm:p-10 2xl:p-12 w-[90vw] sm:w-[540px] lg:w-[720px]"
                :is-open="isOpenContactModal"
                @close="isOpenContactModal = false"
            >
                <ContactForm
                    class="mx-auto"
                    endpoint="/contact"
                    request-type="contact"
                    @submitted="isOpenContactModal = false"
                />
            </NewModal>
        </Teleport>
    </div>
</template>