<script setup>
import {computed, onMounted, onBeforeUnmount, ref} from 'vue';
import {useForm, usePage} from '@inertiajs/vue3';
import {Drawer as FwbDrawer} from 'flowbite';
import Button from '@/Components/Button.vue';
import ContactForm from '@/Components/ContactForm.vue';
import DoorHandleTypes from '@/Components/DoorHandleTypes.vue';
import RadioButtonsGroup from '@/Components/RadioButtonsGroup.vue';
import Slider from '@/Components/Slider.vue';
import Toggle from '@/Components/Toggle.vue';
import Handle from '@/Components/Handle.vue';
import HandleCover from '@/Components/HandleCover.vue';
import ResponsivePicture from "@/Components/ResponsivePicture.vue";
import Drawer from '@/Components/Drawer.vue';
import NewModal from "@/Components/NewModal.vue";
import {find} from "lodash-es";
import {useI18n} from "vue-i18n";

const props = defineProps({
    title: String,
    pageType: String,
    price: [Number, String],
    priceBeforeDiscount: [Number, String],
    doorHeight: Object,
    doorWidth: Object,
    doorOpenOptions: Object,
    doorTrimFabric: Object,
    canvasDecoration: Object,
    doorFrame: Object,
    doorEdge: Object,
    doorCurtains: Object,
    doorMagneticStopper: Object,
    doorMagneticMechanism: Object,
});

const doorHandlesList = computed(() => {
    let list = [];
    const doorHandles = usePage().props?.door_handle || {};
    for (let key in doorHandles) {
        list = [...list, ...doorHandles[key]]
    }
    return list;
});

// handles
const chosenHandle = ref(doorHandlesList.value?.[0].id);
const handles = ref(usePage().props.door_handle)

const chosenHandleComputed = computed(() => {
    if (!handles.value) {
        return null;
    }

    let items = Object.values(handles.value);
    for (let row of items) {
        const item = find(row, {id: chosenHandle.value})
        if (item != null) {
            return row
        }
    }
    return null;
});

const handleCoverWCComputed = computed(() => {
    const item = doorHandlesList.value?.find(item => item.id === chosenHandle.value);
    let el = item.related_products.find(ell => ell.product_type === "door_cover_wc");
    if (!el) {
        el = item.related_products.find(ell => ell.title.includes("WC"));
    }

    return el ? el : null;
});

const cylinderCoverComputed = computed(() => {
    const item = doorHandlesList.value?.find(item => item.id === chosenHandle.value);
    let el = item.related_products.find(ell => ell.product_type === "door_cover_cylinder");
    if (!el) {
        el = item.related_products.find(ell => !ell.title.includes("WC"));
    }

    return el ? el : null;
});

const {t} = useI18n();
const moreHandlesRef = ref(null);
const drawerRef = ref(null);
const drawer = ref(null);

const updatedState = useForm({
    title: props?.title,
    img: usePage()?.props?.coverImage,
    height: props?.doorHeight?.values?.find(el => el.default === true),
    width: props?.doorWidth?.values?.find(el => el.default === true),
    doorOpenOptions: props?.doorOpenOptions?.values?.find(el => el.default === true),
    doorTrimFabric: props?.doorTrimFabric?.values?.[0],
    frame: true,
    doorFrame: props?.doorFrame?.values?.find(el => el.default === true),
    edge: false,
    doorEdge: props?.doorEdge,
    curtains: true,
    doorCurtains: props?.doorCurtains,
    magneticStopper: false,
    doorMagneticStopper: props?.doorMagneticStopper,
    magneticMechanism: true,
    doorMagneticMechanism: props?.doorMagneticMechanism,
    handle: {
        title: t('handle'),
        value: false,
    },
    handleType: {
        title: t('handleType'),
        value: null,
    },
    handleCoverWC: {
        title: t('handleCoverWC'),
        value: false,
    },
    cylinderCover: {
        title: t('cylinderCover'),
        value: false,
    },
    count: 1,
    price: null
});

const totalPrice = computed(() =>
    parseInt((props?.price || 0), 10) +
    parseInt(updatedState.height.price, 10) +
    parseInt(updatedState.width.price, 10) +
    parseInt(updatedState.doorOpenOptions?.price, 10) +
    parseInt((updatedState?.doorTrimFabric?.price || 0), 10) +
    parseInt((updatedState.frame ? updatedState.doorFrame?.price : 0), 10) +
    parseInt((updatedState.edge ? updatedState.doorEdge?.prices?.[updatedState.doorFrame?.key] : 0), 10) +
    parseInt((updatedState.curtains ? updatedState.doorCurtains?.price : 0), 10) +
    parseInt((updatedState.magneticStopper ? updatedState.doorMagneticStopper?.price : 0), 10) +
    parseInt((updatedState.magneticMechanism ? updatedState.doorMagneticMechanism?.price : 0), 10) +
    parseInt((updatedState.handle.value && updatedState.handleType.value?.product_price ?
        updatedState.handleType.value?.product_price.replace(/,/g, '') : 0), 10) +
    parseInt((updatedState.handle.value && updatedState.handleCoverWC.value ?
        handleCoverWCComputed.value.product_price : 0), 10) +
    parseInt((updatedState.handle.value && updatedState.cylinderCover.value ?
        cylinderCoverComputed.value.product_price : 0), 10)
);

const oldPrice = computed(() => parseInt((props.priceBeforeDiscount || 0), 10));
const discount = oldPrice.value === 0 ? 0 : Math.round(((oldPrice.value - totalPrice.value) / oldPrice.value) * 100);
const oldDiscountedPrice = computed(() => {
  return Math.round((totalPrice.value * discount) / 100 + totalPrice.value)
});

const updateHandleCovers = handle => {
    if (!handle) {
        updatedState.handleCoverWC.value = false;
        updatedState.cylinderCover.value = false;
    }
}

const updateCylinderCover = handleCoverWCValue => {
    if (handleCoverWCValue) {
        updatedState.cylinderCover.value = false;
    }
}

const updateHandleCoverWC = cylinderCoverValue => {
    if (cylinderCoverValue) {
        updatedState.handleCoverWC.value = false;
    }
}

const formattedPrice = price => new Intl.NumberFormat('uk-UA', {
    currencyDisplay: "narrowSymbol", style: 'currency', currency: 'UAH'
}).format(price);

const scrollToMoreHandles = () => {
    if (moreHandlesRef.value) {
        const offset = 100;
        const elementPosition = moreHandlesRef.value.getBoundingClientRect().top + window.scrollY;
        const scrollPosition = elementPosition - offset;

        window.scrollTo({top: scrollPosition, behavior: 'smooth'});
    }
}

const isOpenContactModal = ref(false)

const updateChosenHandle = (newHandleId) => {
    chosenHandle.value = newHandleId;
};

onMounted(() => {
    updatedState.handleType.value = chosenHandleComputed.value?.[0];
    drawer.value = new FwbDrawer(drawerRef.value.$el, {
        onHide: () => {
            updatedState.reset()
        }
    });
});

const onAddToCart = () => {

    updatedState.price = totalPrice.value;

    updatedState.post('/cart', {
        preserveState: true,
        preserveScroll: true,
        onSuccess: () => {
            drawer.value.show()
        }
    })
}

onBeforeUnmount(() => {
    drawer.value.hide();
    isOpenContactModal.value = false;
})
</script>

<template>
    <div class="lg:flex lg:gap-5 xl:gap-12">
        <div class="basis-[53.5%]">
            <ResponsivePicture
                :data="$page.props.coverImage"
                img-classes="w-full lg:w-auto"
            />
        </div>
        <div class="content mt-5 lg:mt-0 basis-[46.5%]">
            <div class="lg:mr-6">
                <div class="flex flex-col lg:flex-row md:items-center mb-5 md:justify-center lg:block">
                    <h1 class="text-black text-xl lg:mb-5 font-medium">{{ $page.props.h1 }}</h1>
                    <div class="flex gap-4 items-end">
                        <div class="py-3 lg:py-0">
                            <p class="text-gray-dark line-through text-desc-13 font-medium text-right">
                                {{ formattedPrice(oldDiscountedPrice) }}
                            </p>
                            <p class="py-0 text-black text-2xl font-bold whitespace-nowrap">
                                {{ formattedPrice(totalPrice) }}
                            </p>
                        </div>
                        <div
                            v-if="discount > 0"
                            class="text-desc-13 text-white mb-4 lg:mb-1.5 py-1 px-2 bg-red w-fit h-fit rounded-[14px] font-medium"
                        >
                            -{{ discount }}%
                        </div>
                    </div>
                </div>

                <div class="sizes text-black text-desc-13">
                    <p class="font-medium">{{ t('doorDimensions') }}</p>
                    <div class="my-5 space-y-3">
                        <div
                            v-if="props?.doorHeight"
                            class="height flex items-center"
                        >
                            <p class="mr-6 ml-12 font-medium basis-1/6 max-w-20">{{ props.doorHeight?.title }}</p>
                            <RadioButtonsGroup
                                v-model="updatedState.height"
                                :radios="props.doorHeight?.values"
                                name="height"
                                kind="medium"
                                class="basis-5/6"
                            />
                        </div>
                        <div
                            v-if="props?.doorWidth"
                            class="height flex items-center"
                        >
                            <p class="mr-6 ml-12 font-medium basis-1/6 max-w-20">{{ props.doorWidth?.title }}</p>
                            <RadioButtonsGroup
                                v-model="updatedState.width"
                                :radios="props.doorWidth?.values"
                                name="weight"
                                kind="medium"
                                direction="gridRow"
                                :position="props.doorWidth?.values?.length < 5 ? 'start' : 'between'"
                                class="buttons-row"
                            />
                        </div>
                    </div>
                </div>
                <hr class="border-black">
                <div class="flex flex-col gap-5 xl:flex-row items-center my-5 text-desc-13">
                    <p
                        class="basis-5/12 font-medium text-center xl:text-left"
                        :class="{ 'xl:text-center basis-full': props.pageType !== 'doors-product' }"
                    >
                        {{ t('nonStandardDimensions1') }}
                        <span class="text-red font-semibold">
                            {{ t('nonStandardDimensions2')}}
                        </span>{{t('nonStandardDimensions3')}}
                    </p>
                    <Button
                        v-if="props.pageType === 'doors-product'"
                        kind="big"
                        class="basis-7/12 px-10 xl:px-1 2xl:px-5 2xl:ml-6 xl:self-end"
                        :aria-label="t('connectWithManager')"
                        @click.prevent="isOpenContactModal = true"
                    >
                        {{ t('connectWithManager') }}
                    </Button>
                </div>
                <template v-if="props?.doorOpenOptions">
                    <hr class="border-black">
                    <div class="opening flex gap-3 xl:gap-12 text-black text-desc-13 my-5">
                        <p class="mt-2 font-medium min-w-24 basis-1/6">{{ props.doorOpenOptions?.title }}</p>
                        <RadioButtonsGroup
                            v-model="updatedState.doorOpenOptions"
                            :radios="props.doorOpenOptions.values"
                            direction="column"
                            name="opening"
                            kind="big"
                        />
                    </div>
                </template>
                <div
                    v-if="props?.doorTrimFabric"
                    class="canvas-decoration gap-3 lg:gap-14 flex text-black text-desc-13 my-5"
                >
                    <p class="mt-2 font-medium min-w-24 basis-1/6">{{ props.doorTrimFabric?.title }}</p>
                    <RadioButtonsGroup
                        v-model="updatedState.doorTrimFabric"
                        :radios="props.doorTrimFabric?.values"
                        name="canvas-decoration"
                        class="basis-5/6 flex-wrap space-x-0 lg:flex-nowrap gap-5 lg:gap-1 justify-start"
                    />
                </div>
                <div class="toggles-group flex flex-col space-y-5">
                    <template v-if="props?.doorFrame">
                        <Toggle
                            v-model="updatedState.frame"
                            :label="props.doorFrame?.title"
                        />
                        <div
                            v-if="updatedState.frame"
                            class="opening flex items-baseline sm:items-center gap-8 text-black text-desc-13 mt-4 ml-[54px]"
                        >
                            <p class="font-medium">{{ props.doorFrame?.subtitle }}</p>
                            <RadioButtonsGroup
                                v-model="updatedState.doorFrame"
                                :radios="props.doorFrame.values"
                                name="frame-color"
                                kind="small"
                                direction="gridRow"
                                position="start"
                                class="buttons-row lg:justify-start"
                            />
                        </div>
                    </template>
                    <Toggle
                        v-if="props?.doorEdge"
                        v-model="updatedState.edge"
                        :label="props?.doorEdge?.title"
                    />
                    <Toggle
                        v-if="props?.doorCurtains"
                        v-model="updatedState.curtains"
                        :label="props?.doorCurtains?.title"
                    />
                    <Toggle
                        v-if="props?.doorMagneticStopper"
                        v-model="updatedState.magneticStopper"
                        :label="props?.doorMagneticStopper?.title"
                    />
                    <Toggle
                        v-if="props?.doorMagneticMechanism"
                        v-model="updatedState.magneticMechanism"
                        :label="props?.doorMagneticMechanism?.title"
                    />
                    <Toggle
                        v-model="updatedState.handle.value"
                        :label="updatedState.handle.title"
                        @update:model-value="updateHandleCovers"
                    />
                    <Toggle
                        v-if="updatedState.handle.value && handleCoverWCComputed"
                        v-model="updatedState.handleCoverWC.value"
                        :label="updatedState.handleCoverWC.title"
                        @update:model-value="updateCylinderCover"
                    />
                    <Toggle
                        v-if="updatedState.handle.value && cylinderCoverComputed"
                        v-model="updatedState.cylinderCover.value"
                        :label="updatedState.cylinderCover.title"
                        @update:model-value="updateHandleCoverWC"
                    />
                </div>
            </div>
        </div>
    </div>
    <div class="lg:flex lg:gap-5 xl:gap-12 mt-12">
        <div class="basis-[53.5%] mb-12 lg:mb-0">
            <DoorHandleTypes
                v-if="updatedState.handle.value"
                v-model="updatedState.handleType.value"
                :handle-types="chosenHandleComputed"
                :choosen-handle-id="chosenHandle"
                @chosen-handle-change="updateChosenHandle"
                @scroll="scrollToMoreHandles"
            />
        </div>
        <div class="basis-[46.5%] flex flex-col justify-between">
            <HandleCover
                v-show="updatedState.handleCoverWC.value"
                v-bind="handleCoverWCComputed"
                class="mb-12 lg:mb-0"
            />
            <HandleCover
                v-show="updatedState.cylinderCover.value"
                v-bind="cylinderCoverComputed"
                class="mb-12 lg:mb-0"
            />

            <div class="lg:ml-3 mt-auto">
                <Button
                    v-if="props.pageType === 'doors-custom-product'"
                    type="button"
                    kind="big"
                    class="uppercase w-full font-light"
                    :aria-label="t('connectWithManager')"
                    @click.prevent="isOpenContactModal = true"
                >
                    {{ t('connectWithManager') }}
                </Button>
                <Button
                    v-else
                    kind="big"
                    class="uppercase w-full font-light"
                    :aria-label="t('addToCart')"
                    @click.stop="onAddToCart"
                >
                    {{ t('addToCart') }}
                </Button>
            </div>
        </div>
    </div>
    <div
        v-show="updatedState.handle.value"
        ref="moreHandlesRef"
        class="pt-12"
    >
        <Slider
            :items="doorHandlesList"
            :show-all="true"
        >
            <template #slide="handle">
                <Handle
                    v-model="chosenHandle"
                    v-bind="handle.item"
                />
            </template>
        </Slider>
    </div>
    <Teleport to="body">
        <NewModal
            classes="p-5 sm:p-10 sm:pt-6 2xl:p-10 2xl:pt-4 pt-4 w-[90vw] sm:w-[540px] lg:w-[720px]"
            has-cross
            :is-open="isOpenContactModal"
            @close="isOpenContactModal = false"
        >
            <ContactForm
                class="mx-auto"
                endpoint="/contact"
                :data="updatedState"
                :total-price="totalPrice"
                :request-type="props.pageType"
                @submitted="isOpenContactModal = false"
            />
        </NewModal>
        <Drawer
            ref="drawerRef"
            :close="() => drawer.hide()"
            :cart-item="updatedState"
        />
    </Teleport>
</template>

<style scoped>
.buttons-row {
    grid-template-columns: repeat(auto-fit, minmax(82px, 82px));
}
</style>
