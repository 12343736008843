<script setup>
import {computed, onBeforeUnmount, onMounted, ref} from "vue";
import {Link} from '@inertiajs/vue3';
import ResponsivePicture from "@/Components/ResponsivePicture.vue";
import Button from "@/Components/Button.vue";
import GridBlock from "@/Components/Grid/GridBlock.vue";
import ColBlock from "@/Components/Grid/ColBlock.vue";
import NewModal from "@/Components/NewModal.vue";
import ContactForm from "@/Components/ContactForm.vue";
import emitter from '@/plugins/eventBus.js'
import {scrollToContainer} from "@/plugins/helpers.js";

const isOpenContactModal = ref(false)
const {componentId, content, settings} = defineProps({
    componentId: String,
    content: Object,
    settings: Object
});
const cta = computed(() => content?.cta);
const hasCta = computed(() => content?.cta != null);
const hasImageBlock = computed(() => content?.image_block != null)
const imageBlock = computed(() => content?.image_block)

const contentImage = computed(() => content?.image)

onMounted(() => emitter.$on(componentId, () => scrollToContainer(componentId, 100)));
onBeforeUnmount(() => {
    isOpenContactModal.value = false;
    emitter.$off(componentId);
})
</script>

<template>
    <GridBlock
        :id="componentId"
        class="xl:gap-24 items-center"
        :cols-proportions="settings.col_proportion"
    >
        <ColBlock
            v-if="hasImageBlock"
            class='col-span-2 lg:col-span-1 order-2'
            :cols-proportions="settings.col_proportion"
            :index="settings?.image_block_position === 'right'? 1: 0"
            :class="{'lg:order-2' : settings?.image_block_position === 'right'}"
        >
            <ResponsivePicture
                :data="imageBlock"
                class="mx-auto"
                img-classes="w-full lg:w-auto"
            />
        </ColBlock>
        <ColBlock
            :cols-proportions="settings.col_proportion"
            :index="settings?.image_block_position === 'right'? 0: 1"
            class="order-1"
            :class='{
                "flex flex-col lg:justify-between lg:order-1 items-center lg:items-end": settings?.image_block_position === "right",
                "lg:order-2": settings?.image_block_position !== "right",
            }'
        >
            <Component
                :is="settings.tag ?? 'h3'"
                class="content-heading text-center"
                :class="{
                    'lg:text-right' : settings?.image_block_position === 'right',
                    'lg:text-left' : settings?.image_block_position === 'left',
                    ['font-' + (settings.title_font)]: true
                }"
                :style="{'color': settings.title_color}"
                v-html="content.title"
            ></Component>
            <div
                class="pt-8 text-center font-normal mb-6 md:mb-10 lg:mb-0"
                :class="{
                    'lg:text-right lg:max-w-[670px]' : settings?.image_block_position === 'right',
                    'lg:text-left' : settings?.image_block_position === 'left',
                    ['font-' + (settings.content_font)]: true,
                    'text-2xl md:text-3xl': settings.tag === 'h1',
                    'text-base md:text-xl': settings.tag !== 'h1'
                }"
                :style="{'color': settings.content_color}"
                v-html="content.text"
            ></div>
            <div
                v-if="contentImage"
                class="mt-12"
            >
                <ResponsivePicture
                    :data="contentImage"
                    img-classes="d-block w-full lg:w-auto"
                />
            </div>
            <div
                v-if="hasCta"
                class="flex mt-10 mb-8 sm:mt-16 sm:mb-14 xl:mt-24 lg:mb-0"
            >

                <Button
                    v-if="cta.type === 'action'"
                    kind="big"
                    class="w-fit  font-semibold mx-auto lg:mx-0 lg:mr-auto"
                    :aria-label="cta.title"
                    @click.prevent="isOpenContactModal = true"
                >
                    {{ cta.title }}
                </Button>
                <Link
                    v-else-if="cta.type === 'route'"
                    :href="cta.action"
                    :aria-label="cta.title"
                    :target="cta.target || '_self'"
                    class="
                        block w-fit  font-semibold bg-green
                        text-white text-xl leading-none hover:bg-black rounded-44 transition-colors
                        duration-300 py-4 px-10 mx-auto lg:mx-0 lg:mr-auto
                    "
                >
                    {{ cta.title }}
                </Link>
                <a
                    v-else-if="cta.type === 'link'"
                    :href="cta.action"
                    :aria-label="cta.title"
                    :target="cta.target || '_self'"
                    class="
                        block w-fit  font-semibold bg-green
                        text-white text-xl leading-none hover:bg-black rounded-44 transition-colors
                        duration-300 py-4 px-10 mx-auto lg:mx-0 lg:mr-auto
                    "
                >
                    {{ cta.title }}
                </a>
            </div>

        </ColBlock>

    </GridBlock>

    <Teleport to="body">
        <NewModal
            classes="p-5 sm:p-10 sm:pt-6 2xl:p-10 2xl:pt-4 pt-4 w-[90vw] sm:w-[540px] lg:w-[720px]"
            has-cross
            :is-open="isOpenContactModal"
            @close="isOpenContactModal = false"
        >
            <ContactForm
                class="mx-auto"
                endpoint="/contact"
                :request-type="$page.props.pageType"
                @submitted="isOpenContactModal = false"
            />
        </NewModal>
    </Teleport>
</template>

<style scoped>

</style>
