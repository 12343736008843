<script setup>
import Button from '@/Components/Button.vue';
import {encode} from "js-base64";
import NewModal from "@/Components/NewModal.vue";
import ContactForm from "@/Components/ContactForm.vue";
import {onBeforeUnmount, ref} from "vue";
import ResponsivePicture from "@/Components/ResponsivePicture.vue";

const props = defineProps({
    title: String,
    imgSrc: String,
    profile: Object,
    glass: Object,
    construction: Object,
    accessories: Object,
});

const isOpenContactModal = ref(false)
onBeforeUnmount(() => {
    isOpenContactModal.value = false;
})
</script>

<template>
    <div>
        <div class="lg:flex">
            <!-- Image Section -->
            <div class="lg:w-5/12 mb-4 lg:mb-0">
                <ResponsivePicture
                    :data="$page.props.coverImage"
                    img-classes="w-full lg:w-auto"
                />
            </div>

            <!-- Table Section -->
            <div class="lg:w-7/12 lg:ml-12 text-black">
                <h2 class="text-xl font-medium mt-8 lg:mt-0 mb-4 text-center lg:text-left">{{ props.title }}</h2>
                <div class="divide-y divide-black text-desc-13 font-medium">

                    <template
                        v-for="group in $page.props.partitionOptions"
                        :key="group.id"
                    >
                        <div class="py-4 lg:py-6">
                            <div class="flex my-2">
                                <h3 class="text-desc-13 font-semibold w-1/6 mr-6">{{ group.group_title }}</h3>
                                <div class="grid grid-cols-2 profile-grid gap-6 lg:gap-x-12 w-5/6">
                                    <template
                                        v-for="(value, key) in group.group_options"
                                        :key="encode(value + key)"
                                    >
                                        <div>{{key}}</div>
                                        <div>{{value}}</div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
                <div class="mt-6 lg:mx-16">
                    <Button
                        kind="big"
                        class="w-full"
                        :aria-label="$t('orderCalculation')"
                        @click.prevent="isOpenContactModal = true"
                    >{{ $t('orderCalculation') }}</Button>
                </div>
            </div>
        </div>
    </div>

    <Teleport to="body">
        <NewModal
            classes="p-5 sm:p-10 sm:pt-6 2xl:p-10 2xl:pt-4 pt-4 w-[90vw] sm:w-[540px] lg:w-[720px]"
            has-cross
            :is-open="isOpenContactModal"
            @close="isOpenContactModal = false"
        >
            <ContactForm
                class="mx-auto"
                endpoint="/contact"
                :request-type="$page.props.pageType"
                @submitted="isOpenContactModal = false"
            />
        </NewModal>
    </Teleport>
</template>

<style scoped>
.profile-grid {
    grid-template-columns: 1fr 2fr;
}
</style>