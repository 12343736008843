<script setup>
import {Link} from "@inertiajs/vue3";
import {useI18n} from "vue-i18n";
import {computed} from "vue";

const locale = computed(() => useI18n().locale);
</script>

<template>
    <Link
        :href="locale.value === 'uk' ? '/cart' : `/${locale.value}/cart`"
        class="relative h-fit"
        aria-label="Go to cart"
    >
        <svg
            class="text-black hover:text-green transition-colors duration-300"
            xmlns="http://www.w3.org/2000/svg"
            width="34"
            height="28"
            viewBox="0 0 34 28"
            fill="none"
        >
            <g clip-path="url(#clip0_2901_2397)">
                <path
                    d="M12.5967 3.20812H32.0891C32.8201 3.20812 33.4089 3.79695 33.4089 4.52792C33.4089 4.62944 33.3886 4.75127 33.3683 4.85279L31.3581 17.2589C31.2566 17.9086 30.6881 18.3756 30.0587 18.3553H12.5967C11.8658 18.3553 11.2972 17.7665 11.2769 17.0558L10.1196 4.73096L9.91652 2.61929H1.53073C0.79977 2.63959 0.210938 2.05076 0.210938 1.3198C0.210938 0.588833 0.79977 0 1.53073 0H11.1145C11.7845 0 12.3531 0.507614 12.414 1.19797L12.5967 3.20812ZM30.546 5.82741H12.8607L13.7947 15.736H28.9622L30.546 5.82741ZM27.947 22.7208C28.6779 22.7208 29.3277 23.0254 29.815 23.4924C29.8556 23.533 29.8759 23.5533 29.9165 23.5939C30.3429 24.0609 30.5866 24.6701 30.5866 25.3401C30.5866 26.0711 30.282 26.7208 29.815 27.2081C29.348 27.6954 28.6779 27.9797 27.947 27.9797C27.216 27.9797 26.5663 27.6751 26.079 27.2081C25.612 26.7411 25.3074 26.0711 25.3074 25.3401C25.3074 24.6294 25.5917 23.9594 26.079 23.4924C26.5663 23.0254 27.216 22.7208 27.947 22.7208ZM15.3581 22.7208C16.0891 22.7208 16.7389 23.0254 17.2262 23.4924C17.2668 23.533 17.2871 23.5533 17.3277 23.5939C17.7541 24.0609 17.9977 24.6701 17.9977 25.3401C17.9977 26.0711 17.6932 26.7208 17.2262 27.2081C16.7592 27.6954 16.0891 27.9797 15.3581 27.9797C14.6272 27.9797 13.9774 27.6751 13.4901 27.2081C13.0231 26.7411 12.7186 26.0711 12.7186 25.3401C12.7186 24.6294 13.0231 23.9594 13.4901 23.4924C13.9774 23.0254 14.6475 22.7208 15.3581 22.7208ZM12.5967 22.1929C11.8658 22.1929 11.2769 21.6041 11.2769 20.8731C11.2769 20.1421 11.8658 19.5533 12.5967 19.5533H30.079C30.8099 19.5533 31.3988 20.1421 31.3988 20.8731C31.3988 21.6041 30.8099 22.1929 30.079 22.1929H12.5967Z"
                    class="fill-current"
                />
            </g>
            <defs>
                <clipPath id="clip0_2901_2397">
                    <rect
                        width="33.1777"
                        height="28"
                        fill="white"
                        transform="translate(0.210938)"
                    />
                </clipPath>
            </defs>
        </svg>
        <span class="sr-only">Notifications</span>
        <div
            v-if="$page.props.cart_count > 0"
            class="bg-green absolute inline-flex items-center justify-center w-4 h-4 text-xxs font-medium text-white rounded-full -top-2 -end-2"
        >
            {{ $page.props.cart_count }}
        </div>
    </Link>
</template>
