<script setup>

import classNames from "classnames";

const props = defineProps({
    kind: String,
    border: Boolean,
    borderColor: String,
    primary: Boolean,
    secondary: Boolean
});

const primaryClasses = props.primary ? 'bg-green hover:bg-black text-white border-gray-darkest' : '';
const secondaryClasses = props.secondary ? 'bg-transparent text-black' : '';

const btnClasses = {
    'sharp-small': `py-4 px-5 border ${secondaryClasses} ${primaryClasses} ${props.borderColor || ''} text-xss font-medium flex justify-center items-center transition-colors duration-300`,
    sharp: 'py-8 px-5 2xl:px-14 border text-xl text-green border-green flex justify-center sm:justify-around 2xl:justify-between gap-5 sm:gap-0 items-center transition-colors duration-300',
    small: 'bg-green text-white text-xxs font-semibold hover:bg-black py-2 px-4 rounded-2xl transition-colors duration-300',
    big: 'bg-green text-white text-xl leading-none font-semibold hover:bg-black py-4 px-10 rounded-44 transition-colors duration-300',
    'round-big': 'bg-green text-white rounded-full hover:bg-black md:h-24 md:w-24 h-14 w-14 flex justify-center items-center transition-colors duration-300',
    'round-small': 'bg-green text-white rounded-full hover:bg-black h-12 w-12 md:h-14 md:w-14 flex justify-center items-center transition-colors duration-300',
    'round-extra-small': 'bg-green text-white rounded-full hover:bg-black h-8 w-8 md:h-14 md:w-14 flex justify-center items-center transition-colors duration-300',
}
</script>

<template>
    <button
        :class="classNames(btnClasses[props.kind])"
        role="button"
    >
        <slot></slot>
    </button>
</template>
